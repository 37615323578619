<template>
    <div class="login-box">
        <div class="bg-box">
            <img src="../../../assets/login-bg.png" alt />
        </div>
        <div class="submit-box">
            <van-form @submit="onSubmit">
                <van-field
                    v-model="username"
                    name="mobile"
                    label="手机号"
                    placeholder="请输入手机号"
                    :rules="[{ pattern, message: '请检查手机号' }]"
                />
                <van-field
                    v-model="password"
                    type="password"
                    name="password"
                    label="密码"
                    placeholder="请输入密码"
                    autocomplete="tel"
                    :rules="[{ required: true, message: '请检查密码' }]"
                />
                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit" color="#FB748F">登录</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>


<script>
import {
    Form,
    Field,
    Button
} from "vant";
import { loginTeacher } from "@/api/login"
import { setLocalStorage } from "@/utils/cache"
export default {
    props: ["MechanismCheckObject"],
    components: {
        [Form.name]: Form,
        [Field.name]: Field,
        [Button.name]: Button,
    },
    data() {
        return {
            username: "16632259511",
            password: "",
            pattern: /\d{11}/,
        }
    },
    methods: {
        onSubmit(values) {
            loginTeacher(values).then(res => {
                const { data } = res
                if (data.code == 200) {
                    setLocalStorage("token", data.data.token)
                    this.$router.push("/")
                }
            })
        }
    },
}
</script>

<style lang="less" scoped>
.login-box {
    width: 100%;
    height: 100%;
    position: relative;
    background: #fafafa;
    .bg-box {
        width: 100%;
        height: 450px;
        > img {
            width: 100%;
            height: 450px;
        }
    }
    .submit-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 330px;
        height: 230px;
        background: white;
        border-radius: 10px;
        padding: 10px;
    }
}
</style>
